import React from 'react'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { Link, graphql} from 'gatsby'
import Recipeslist from '../components/Recipeslist'
import SEO from "../components/SEO"

const About = ({data:{allContentfulBengaliPlate:{nodes:recipes}}}) => {
  return (
    <Layout>
    <SEO title="About" />
     <main className='page'>
     <section className='about-page'>
     <article>
      <h2>We are here to provide you Bengali food recipe</h2>
      <p>We are bengali. We love to eat. And also we love to 
          see some one is eating with great satisfaction. </p>
          <p>A study has shown that food we perceive to have been
           'made with love' tastes better. The results suggest that our emotional perception 
           of taste can be enhanced or diminished by the amount of time, love and care that goes 
           into meals, which ultimately can increase our enjoyment of food.</p>
          
          <Link to='/contact' className='btn'>
            Contact
          </Link>
     </article>
     <StaticImage src="../assets/images/payesh.jpg"
      alt="payesh-img"
        className='about-img'
        placeholder="blurred"
      />

     </section>
     <br/>
     <br/>
     <br/>

     <section className='featured-recipes'>
   <h5>Look At This Awsomesouce</h5>
   <Recipeslist recipes={recipes}/>
     </section>
     
     

     </main>
     <br/>
     <br/>
     <br/>
      
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulBengaliPlate(
      sort: {fields: title, order: ASC}
      filter: {feature: {eq: true}}
    ) {
      nodes {
        id
        title
        cookTime
        prepTime
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      totalCount
    }
  }
`

export default About
